import React from "react";
import { createRoot } from "react-dom";
import { calculatePublicPath } from "canopy-sofe-extensions";
import App from "./app.component.js";
import Badge from "./badge.component.js";

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appDisplayed: false,
    };
  }

  render() {
    // Doesn't hurt to show the badge even when the app is open, since it's z-index is smaller than App's
    return (
      <div>
        <App
          toggleAppVisibility={this.toggleAppVisibility}
          hideApp={this.hideApp}
          visible={this.state.appDisplayed}
          {...this.props}
        />
        <Badge toggleAppVisibility={this.toggleAppVisibility} {...this.props} />
      </div>
    );
  }

  hideApp = () => {
    this.setState({
      appDisplayed: false,
    });
  };

  toggleAppVisibility = () => {
    this.setState((prevState) => ({ appDisplayed: !prevState.appDisplayed }));
  };
}

__webpack_public_path__ = calculatePublicPath("sofe-inspector"); // eslint-disable-line

export default function boot(props) {
  let wrapper = document.createElement("div");
  wrapper.id = "sofe-inspector";

  document.body.appendChild(wrapper);
  const root = createRoot(wrapper);
  root.render(<Root {...props} />);
}
